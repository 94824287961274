<template>
    <header class="px-5 md:px-6 intro-header">
      <div class="wrapper mx-auto relative h-full">
        <div class="desc-haut-gauche absolute top-4 w-1/3 lg:w-full"><img src="../../public/images/sous-logo-entete-web-design.svg" alt="EFFSET - WEB DESIGN"></div>
        <div class="absolute  inset-y-2/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 logo-intro">
        
          <img src="../../public/images/effset-web-beige.svg">
        </div>
        <div class="curseur w-8" id="curseur" >
    
            <img src="../../public/images/pastille-vers-bas.svg" alt="PASTILLE EFFSET DEVELOPEMENT WEB LEVIS">


        </div>
      </div> 
    </header>
    <main class="px-5 md:px-6 total-main" id="mainView">
      <div class="mx-auto wrapper-main grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-10">
        <div class="top-main md:col-span-2 pt-6 pb-4 lg:pt-8 lg:pb-6">
        
          <div class="logo-main">
            <img src="../../public/images/effset-developpement-web-menu.svg" class="w-9/12 md:w-full">
            
          </div>
        
        <div class="sociaux">
        <a href="http://instagram.com/effset" target="_blank" >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
          </svg>
        </a>
        <a href="https://www.facebook.com/effsetweb" target="_blank" class="md:ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/>
          </svg>
        </a>
        
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="md:ml-2">
          <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/>
        </svg> 
          
          </div>
        </div>
        <div class="description order-3 md:order-2 mb-6 md:mb-0">
          
            <h2 class="pb-6 md:pb-8">Nous sommes Karrel et Frédérik.</h2>
            <p>
             Unir nos forces et nos passions, telle a été notre motivation pour créer notre propre entreprise en développement Web.
          </p>
        </div>
        <div class="image-intro lg:pl-12 order-2 md:order-3 row-span-2">
          <img src="../../public/images/effset-web-karrel-frederik.jpg" class="image-fred-karrel" alt="EFFSET WEB DESIGN - Karrel / Frédérik">
          
        </div>

      <div class="services order-4 col-span-1 md:col-span-2 lg:col-span-1">
        <div class="titre-services">
          <h3>NOS SERVICES</h3>
          
        </div>
        <div class="liste-services grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
          <div class="bloc-services">Design Web</div>
          <div class="bloc-services">Création de logo</div>
          <div class="bloc-services">Photographie corporative</div>
          <div class="bloc-services">Expérience utilisateur</div>
          <div class="bloc-services">Système d'identité</div>
          <div class="bloc-services">Photographie de produits</div>
          <div class="bloc-services">Développement Web</div>
          <div class="bloc-services">Directives de la marque</div>
          <div class="bloc-services">Photographie &#171;lifestyle&#187; </div>
        </div>

      </div>
      <div class="action order-4 col-span-1 md:col-span-2 lg:col-span-1">
        
          <button type="button" class="button-contact" @click="bonjour()">Dites-nous un beau bonjour!
             
          </button>
      
        </div>
      </div>
    </main>

 
    <footer class="form-bottom">
      <div class="wraper-footer">
        <div class="titres-holder grid grid-cols-1 md:grid-cols-3 gap-10 lg:gap-30 px-5 md:px-6">
          <div class="titres-forms pt-7" id="section-savoir" @click="handleTypeForm(1)"><h4>En savoir un peu plus</h4></div>
          <div class="titres-forms pt-2 md:pt-7" id="section-discuter" @click="handleTypeForm(2)"><h4>Discuter avec Fred ou Karrel</h4></div>
          <div class="titres-forms pt-2 md:pt-7" id="section-debuter" @click="handleTypeForm(3)"><h4>Débuter mon projet!</h4></div>
        </div>

      <!-- FORMULAIRE EN SAVOIR EN PEU PLUS    -->
      <div class="footer-center"> 

      <transition-group name="slide">

      <div v-if="typeForm === 1" class="grid grid-cols-1 lg:grid-cols-3 gap-2 pt-16 md:pt-28 lg:pt-30 px-5 md:px-6 w-full md:w-11/12"  @mouseenter="handleSubmitButton('btnSavoir')">
     
        <div class="mb-4" @mouseenter="handleSubmitButton('btnSavoir')">
          <label for="prenom" class="block" required>Prénom</label>
          <input  v-model="savoir.prenom" type="text" id="prenom" :placeholder="savoirPrenomValidate" class="focus:outline-none rounded px-2 py-2 mt-2 w-full md:w-9/12">
  
        </div>
        <div class="mb-4">
          <label for="nom" class="block" @mouseenter="handleSubmitButton('btnSavoir')">Nom</label>
          <input v-model="savoir.nom" type="text" id="nom" :placeholder="savoirNomValidate" class="focus:outline-none rounded px-2 py-2 mt-2 w-full md:w-9/12">
         
        </div>
        <div class="mb-4">
          <label for="courriel" class="block" @mouseenter="handleSubmitButton('btnSavoir')" >Courriel</label>
          <input v-model="savoir.courriel" type="email" id="courriel" :placeholder="savoirEmailValidate" class="focus:outline-none rounded px-2 py-2 mt-2 w-full md:w-9/12">
          
        </div>
        <div class="mb-4 lg:col-span-2" @mouseenter="handleSubmitButton('btnSavoir')">
          <label for="message" class="block">Votre message</label>
          <textarea  v-model="savoir.message" :placeholder="savoirMessageValidate" class="form-textarea focus:outline-none rounded mt-2 w-full md:w-full lg:w-10/12 px-2 py-2" rows="4"></textarea>

        </div>
        <div class="button-envoyer-wrapper mb-6 lg:mt-9" @mouseenter="handleSubmitButton('btnSavoir')">
            <button type="button" class="btn-envoyer m-auto lg:m-0" @click="sendForm('savoir')"  id="btnSavoir" :disabled="!savoirIsActive">
               <span v-if="loadingInformations === 0">Envoyer</span>
                <span v-else-if="loadingInformations === 1" class="centerIcon">
                  <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </span>
                <span v-else-if="loadingInformations === 2" class="centerChecked"><img src="../../public/images/checked.svg"></span>

            </button>
        </div>
  
      </div>

  
      <!-- FORMULAIRE DISCUTER AVEC FRED OU KARREL -->
       <div v-if="typeForm === 2" class="gap-10 grid grid-cols-1 lg:grid-cols-2 px-5 md:px-6 pt-16 md:pt-28 lg:pt-34 w-full lg:w-11/12">

         <div class="blocProfil grid grid-cols-5">
            <div class="desc col-span-5 md:col-span-3 m-auto lg:m-0 w-10/12 lg:w-full">     
                <p class="pb-7">DES QUESTIONS SUR :</p>
                <p>Conception design Web</p>
                <p>Conception d'image de marque</p>
                <p>Photographie de produits</p>
                <p>Photographie corporative</p>

            </div>
            <div class="photo col-span-5 md:col-span-2  m-auto lg:m-0 mt-10 lg:mt-0">
                <img src="../../public/images/karrel.jpg" alt="Karrel" class="h-48 w-48 md:h-44 md:w-44 rounded-full">
            </div>
            <div class="fleche-courriel hidden animate-pulse col-span-2  lg:flex lg:justify-end lg:mb-14 mt-4  lg:col-span-3  pr-10">
              <img src="../../public/images/fleche-vers-droit-contact.svg" alt="Contacter Karrel Aubert - EFFSET WEB" class="md:h-16 lg:h-full">
            </div>
            <div class="col-span-5 lg:col-span-2 lien-courriel w-48 pt-4 m-auto lg:m-0 pb-10 lg:pb-0">
              <p>Karrel</p>
              <a href="mailto:karrel@effset.ca">karrel@effset.ca</a>
            </div>
         </div>

         <div class="blocProfil grid grid-cols-5 mt-10 lg:mt-0">
             <div class="desc col-span-5 md:col-span-3 m-auto lg:m-0 w-10/12 lg:w-full" id="bloc-desc-fred">
             
                <p class="pb-7">DES QUESTIONS SUR :</p>
                <p>Développement Web</p>
                <p>Programmation Web</p>
                <p>Hébergement Web</p>
                <p>Interface Client / Serveur</p>
   
            </div>
             <div class="photo col-span-5 md:col-span-2 m-auto lg:m-0 mt-10 lg:mt-0">
                <img src="../../public/images/fred.jpg" alt="Frédérik - Développeur WEB" class="h-48 w-48 md:h-44 md:w-44 rounded-full">
            </div>
            <div class="fleche-courriel hidden animate-pulse lg:flex lg:justify-end lg:mb-14 mt-4 col-span-5 lg:col-span-3  pr-10">
              <img src="../../public/images/fleche-vers-droit-contact.svg" alt="Contacter Frédérik Guay - EFFSET WEB">
            </div>
            <div class="col-span-5 lg:col-span-2 lien-courriel w-48 pt-4 m-auto lg:m-0 pb-20 lg:pb-0 mb-18">
              <p>Frédérik</p>
              <a href="mailto:frederik@effset.ca">frederik@effset.ca</a>
            </div>
      
         </div>
      </div>
    
      <!-- FORMULAIRE DEBUTER MON PROJET -->

      <div  v-if="typeForm === 3" class="grid grid-cols-1 lg:grid-cols-3 gap-2 pt-8 md:pt-16 pb-8 px-5 md:px-6 w-full md:w-11/12" @mouseenter="handleSubmitButton('btnProjet')">
        <div class="mb-4">
          <label for="prenom" class="block">Prénom</label>
          <input  v-model="debuter.prenom" type="text" id="prenom" :placeholder="debuterPrenomValidate" class="focus:outline-none rounded px-2 py-2 mt-2 w-full md:w-9/12">
        </div>
        <div class="mb-4">
          <label for="nom" class="block">Nom</label>
          <input  v-model="debuter.nom" type="text" id="nom" :placeholder="debuterNomValidate" class="focus:outline-none rounded px-2 py-2 mt-2 w-full md:w-9/12">
        </div>
        <div class="mb-4">
          <label for="courriel" class="block">Courriel</label>
          <input  v-model="debuter.courriel" type="email" id="courriel" :placeholder="debuterEmailValidate" class="focus:outline-none rounded px-2 py-2 mt-2 w-full md:w-9/12">
        </div>


      <!-- CHOIX PROJET -->
      <div class="mt-7 mb-4 col-span-1 lg:col-span-3 grid grid-cols-3 "  @mouseenter="handleSubmitButton('btnProjet')">
        <div class="col-span-3 md:mb-5">
          <p class="texte-projet italic">Quel type de projet vous intéresse</p>
        </div>
        <div class="col-span-3 pt-4 lg:pt-0 lg:col-span-1">
          <label class="inline-flex items-center">
            <input type="radio" class="form-radio" name="typeProjet" value="visuel" v-model="debuter.type">
            <span class="ml-2 texte-projet">J’ai déjà mon site Web et mon hébergement. Je souhaite refaire le visuel de mon site.</span>
          </label>
        </div>
        <div  class="col-span-3  pt-4 lg:pt-0 lg:col-span-1">
            <label class="inline-flex items-center">
              <input type="radio" class="form-radio" name="typeProjet" value="image" v-model="debuter.type">
              <span class="ml-2 texte-projet">Je souhaite développer l’image de ma nouvelle entreprise (Logo et site Web)</span>
            </label>
          </div>
          <div  class="col-span-3  pt-4 lg:pt-0 lg:col-span-1">
            <label class="inline-flex items-center">
              <input type="radio" class="form-radio" name="typeProjet" value="imageBoutique" v-model="debuter.type">
              <span class="ml-2 texte-projet">Je souhaite développer l’image de ma nouvelle entreprise (Logo et site Web) et réaliser une boutique en ligne.</span>
            </label>
          </div>
      </div>
      <!-- FIN CHOIX PROJET ---->
      <div class="mb-4 col-span-1 lg:col-span-2" @mouseenter="handleSubmitButton('btnProjet')">
        <label for="message" class="block">Votre message</label>
        <textarea  v-model="debuter.message" :placeholder="debuterMessageValidate" class="form-textarea focus:outline-none rounded mt-2 block w-full md:w-full lg:w-11/12 px-2 py-2" rows="4"></textarea>
      </div>
      <div class="button-envoyer-wrapper mb-6 lg:mt-9 text-center">
          <button type="button" class="btn-envoyer m-auto lg:m-0" @click="sendForm('debuter')" id="btnProjet" :disabled="!debuterIsActive">
            <span v-if="loadingDebuter === 0">Envoyer</span>
              <span v-else-if="loadingDebuter === 1" class="centerIcon">
                <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </span>
            <span v-else-if="loadingDebuter === 2" class="centerChecked"><img src="../../public/images/checked.svg"></span>
          </button>
      </div>
      
      </div>
      <!-- END TYPEFORM3 -->
          </transition-group>
          </div>
      </div>

    </footer>
</template>

<script>

import ScrollTrigger from "gsap/ScrollTrigger";
import CSSRulePlugin from "gsap/CSSRulePlugin";
 
var validator = require("email-validator");
 

 
import { onMounted, ref, reactive } from 'vue';
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSRulePlugin);

export default {
  name: 'Home',
  setup() {
    const animateButton = ref(true);
    const typeForm = ref(1);
    const debuter = reactive({});
    const savoir = reactive({});
    const middle = reactive({});
    const btnPosition = ref({'center':{'x': 0, 'y': 0}});
    const loadingDebuter = ref(0);
    const loadingInformations = ref(0);


    const savoirNomValidate = ref();
    const savoirEmailValidate = ref();
    const savoirMessageValidate = ref();
    const savoirPrenomValidate = ref();
    const savoirIsActive = ref(true);



    const debuterNomValidate = ref();
    const debuterEmailValidate = ref();
    const debuterMessageValidate = ref();
    const debuterPrenomValidate = ref();
    const debuterIsActive = ref(true);



    const clearForm = () => {
        savoir.message = '';
        savoir.prenom = '';
        savoir.nom = '';
        savoir.courriel = '';
        debuter.message = '';
        debuter.prenom = '';
        debuter.nom = '';
        debuter.type = '';
        debuter.courriel = '';

    }

    function handleWidth() {
        var w = window.innerWidth;
        if (w > 640){
          return true;
        }else{
          return false;
        }
      
      }

       function handleHeightCursor() {
        var w = window.innerWidth;
        if (w > 640){
          return 60;
        }else{
          return 130;
        }
      
      }

    const  sendForm = (typeEmail) => {
      if(typeEmail === 'debuter'){

           if(debuter.prenom === undefined || debuter.prenom === 'undefined' || debuter.prenom === null || debuter.prenom === ''){
            debuterPrenomValidate.value = "Veuillez remplir ce champ";
            return;
          }else{
             debuterPrenomValidate.value = "";
          }


          if(debuter.nom === undefined || debuter.nom === 'undefined' || debuter.nom === null || debuter.nom === ''){
             debuterNomValidate.value = "Veuillez remplir ce champ";
             return;
          }else{
             debuterNomValidate.value = "";
          }


         
            if(debuter.message === undefined || debuter.message === 'undefined' || debuter.message === null || debuter.message === ''){
             debuterMessageValidate.value = "Veuillez remplir ce champ";
             return;
          }else{
            debuterMessageValidate.value = '';
          }


     
      
            if(debuter.courriel  === undefined || debuter.courriel  === 'undefined' || debuter.courriel  === null || debuter.courriel  === ''){
             debuterEmailValidate.value = "Veuillez remplir ce champ";
             return;
          }else{
             debuterEmailValidate.value = "";
          }

          
          if(validator.validate(debuter.courriel) === false){

            debuter.courriel = '';
            debuterEmailValidate.value = "Courriel invalide";
            return;
          }else{
            debuterEmailValidate.value = "";
          }




          loadingDebuter.value = 1;
           const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              'message' : debuter.message,
              'prenom' : debuter.prenom,
              'nom' : debuter.nom,
              'type' : debuter.type,
              'email' : debuter.courriel,

             })
          };

          fetch("https://effset.ca/email/projet", requestOptions)
            .then(async response => {
              const data = await response.json();

              // check for error response
              if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
              }
              animateButton.value = false;
              clearForm();
              loadingDebuter.value = 2;
              debuterIsActive.value = false;
            
            })
            .catch(error => {
              loadingDebuter.value = 0;
              console.log(error);
            });

      }else{


          if(savoir.prenom === undefined || savoir.prenom === 'undefined' || savoir.prenom === null || savoir.prenom === ''){
            savoirPrenomValidate.value = "Veuillez remplir ce champ";
            return;
          }else{
             savoirPrenomValidate.value = "";
          }


          if(savoir.nom === undefined || savoir.nom === 'undefined' || savoir.nom === null || savoir.nom === ''){
             savoirNomValidate.value = "Veuillez remplir ce champ";
             return;
          }else{
             savoirNomValidate.value = "";
          }


          if(savoir.message === undefined || savoir.message === 'undefined' || savoir.message === null || savoir.message === ''){
             savoirMessageValidate.value = "Veuillez remplir ce champ";
             return;
          }else{
            savoirMessageValidate.value = '';
          }


     
          if(savoir.courriel === undefined || savoir.courriel === 'undefined' || savoir.courriel === null || savoir.courriel === ''){
             savoirEmailValidate.value = "Veuillez remplir ce champ";
             return;
          }else{
             savoirEmailValidate.value = "";
          }

               
            if(validator.validate(savoir.courriel) === false){
           
            savoir.courriel = '';
            savoirEmailValidate.value = "Courriel invalide";
            return;
          }else{
            savoirEmailValidate.value = "";
          }


         



          loadingInformations.value = 1;
           const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              'message' : savoir.message,
              'prenom' : savoir.prenom,
              'nom' : savoir.nom,
              'email' : savoir.courriel,

             })
          };



          fetch("https://effset.ca/email/infos", requestOptions)
             .then(async response => {
              const data = await response.json();

            
              if (!response.ok) {
               
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
              }
              animateButton.value = false;
              clearForm();
              loadingInformations.value = 2;
              savoirIsActive.value = false;
        
              
            })
            .catch(error => {
              loadingInformations.value = 0;
              console.log(error)
              
             
            });

      
    }}


    function getElementTopLeft(element) {

        let bouttonEnvoyer = document.querySelector(`#${element}`);

        if(bouttonEnvoyer){
          var ele = bouttonEnvoyer;
              var top = 0;
              var left = 0;
              var width = 0;
              var height = 0;
              var centerY = 0;
              var centerX = 0;
          
              top += ele.offsetTop;
              width = ele.offsetWidth;
              left += ele.offsetLeft;
              height = ele.offsetHeight;
              centerY = ( (top + (top + height)) / 2);
              centerX = ( (left + (left + width)) / 2);
              ele = ele.offsetParent;
                
              btnPosition.value = { 'MIDDLE' : middle, top: top, left: left, width: width, height: height, center: {'x': centerX, 'y' : centerY}};
  
              let translateX = 0;
              let translateY = 0;
              let buffer = 200;
              let range = 35;
              let speed = 4;
              let rect;

            window.addEventListener('mousemove',  (e) =>  {
    
            rect = document.querySelector('.form-bottom').getBoundingClientRect();

            let x = e.clientX - rect.left; //x position within the element.
            let y = e.clientY - rect.top;  //y position within the element. 
            middle.x = x;
            middle.y = y;

    
            translateX = (middle.x - btnPosition.value.center.x ) / speed;
            translateY = (middle.y - btnPosition.value.center.y) / speed;


            if(((middle.x - btnPosition.value.center.x) < buffer) && ((btnPosition.value.center.x - middle.x) < buffer) 
            && ((middle.y - btnPosition.value.center.y) < buffer) && ((btnPosition.value.center.y - middle.y) < buffer))
            {
            

            if(translateX > range){
              translateX = range;
            }else if(translateX < -range){
               translateX = -range;
            }
            if(translateY > range){
              translateY = range;
            }else if(translateY < -range){
              translateY = -range;
            }
         
            if(bouttonEnvoyer !== null  && animateButton.value !== false && handleWidth()){
            bouttonEnvoyer.style.transform = "scale(1.15)";
            bouttonEnvoyer.style.transform += `translate(${translateX}px, ${translateY}px)`;
            }
            

            }else{
              if(bouttonEnvoyer !== null){
              bouttonEnvoyer.style.transform = `translate(0, 0)`;
              }
            }
        });
    }
}

      const handleSubmitButton = (element) => {
           getElementTopLeft(element)
        }




    const onLoad = onMounted(() => {
      window.scrollTo({ top: 0})
      document.querySelector('#section-savoir').style.color = '#F8F9F6';
      document.querySelector('#section-savoir').style.fontStyle = 'italic';

      gsap.fromTo(".curseur", {bottom: "40%"}, {bottom: `${handleHeightCursor()}px`, duration: 1 });
      gsap.fromTo(".desc-haut-gauche", {opacity: 0}, {opacity: 1, duration: 1.5});

      var beenScrolled = false;
      window.addEventListener("scroll", () => {

        let scroll = window.scrollY;
        
        if( scroll < window.innerHeight && !beenScrolled){
                let heightMain = document.querySelector('.total-main').offsetHeight;
                // STYLE ON PRE SELECTED FORM
                document.querySelector('.form-bottom').style.top = `${heightMain}px`;
                document.querySelector('#section-savoir').style.setProperty("--check-secondary", "#F8F9F6");
        }else{
          
          if(!beenScrolled){
              beenScrolled = true;
              document.querySelector('.intro-header').style.display = 'none';
              document.querySelector('.total-main').style.position = 'relative';
              document.querySelector('.total-main').style.top = null;
              document.querySelector('.form-bottom').style.top = null;
              window.scroll({ top: 0, behavior: 'auto' })
          }
        }

      });

    })


     const bonjour = () => {
          let toScroll = document.querySelector('.total-main').offsetHeight + 100;
         
          window.scrollTo({ top: toScroll, behavior: 'smooth' });
      }

    const handleTypeForm = form => {
      if(form === 1){

        // FORMULAIRE EN SAVOIR EN PEU PLUS
        typeForm.value = 1;
        handleSubmitButton('btnSavoir')
        // COULEUR DU TEXTE
        document.querySelector('#section-savoir').style.color = '#F8F9F6';
        document.querySelector('#section-discuter').style.color = '#787B7B';
        document.querySelector('#section-debuter').style.color = '#787B7B';

        // STYLE DU TEXTE

        document.querySelector('#section-savoir').style.fontStyle = 'italic';
        document.querySelector('#section-discuter').style.fontStyle = 'normal';
        document.querySelector('#section-debuter').style.fontStyle = 'normal';

        // COULEUR DE DU BORDER BOTTOM 
        document.querySelector('#section-savoir').style.setProperty("--check-secondary", "#F8F9F6");
        document.querySelector('#section-discuter').style.setProperty("--check-secondary", "#787B7B");
        document.querySelector('#section-debuter').style.setProperty("--check-secondary", "#787B7B");

      }else if(form === 2){
        typeForm.value = 2;
         // COULEUR DU TEXTE
        document.querySelector('#section-savoir').style.color = '#787B7B';
        document.querySelector('#section-discuter').style.color = '#F8F9F6';
        document.querySelector('#section-debuter').style.color = '#787B7B';

        // STYLE DU TEXTE

        document.querySelector('#section-savoir').style.fontStyle = 'normal';
        document.querySelector('#section-discuter').style.fontStyle = 'italic';
        document.querySelector('#section-debuter').style.fontStyle = 'normal';
        
        // COULEUR DE DU BORDER BOTTOM 
        document.querySelector('#section-savoir').style.setProperty("--check-secondary", "#787B7B");
        document.querySelector('#section-discuter').style.setProperty("--check-secondary", "#F8F9F6");
        document.querySelector('#section-debuter').style.setProperty("--check-secondary", "#787B7B");
      }else{
        typeForm.value = 3;
        handleSubmitButton('btnProjet');
         // COULEUR DU TEXTE
        document.querySelector('#section-savoir').style.color = '#787B7B';
        document.querySelector('#section-discuter').style.color = '#787B7B';
        document.querySelector('#section-debuter').style.color = '#F8F9F6';

        // STYLE DU TEXTE

        document.querySelector('#section-savoir').style.fontStyle = 'normal';
        document.querySelector('#section-discuter').style.fontStyle = 'normal';
        document.querySelector('#section-debuter').style.fontStyle = 'italic';

        // COULEUR DE DU BORDER BOTTOM 
        document.querySelector('#section-savoir').style.setProperty("--check-secondary", "#787B7B");
        document.querySelector('#section-discuter').style.setProperty("--check-secondary", "#787B7B");
        document.querySelector('#section-debuter').style.setProperty("--check-secondary", "#F8F9F6");
      }
    }


    return {
      onLoad, 
      typeForm, 
      handleTypeForm, 
      debuter, 
      savoir,
      bonjour,
      handleSubmitButton,
      getElementTopLeft,
      middle,
      btnPosition,
      sendForm,
      loadingInformations,
      loadingDebuter,
      clearForm,
      handleWidth,
      animateButton,
      savoirNomValidate,
      savoirPrenomValidate,
      savoirEmailValidate,
      savoirMessageValidate,
      debuterNomValidate,
      debuterEmailValidate,
      debuterMessageValidate,
      debuterPrenomValidate,
      handleHeightCursor,
      savoirIsActive,
      debuterIsActive

      
    } 
  }
  

}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Piazzolla:wght@100&display=swap');
$myFont: 'Piazzolla', serif;
$myColor: #9E6850;
$myBlack: #24272A;
$myWhite: #F8F9F6;
$myGray: #787B7B;
$myLessGray: #DADADA;





.slide-enter-from{
  opacity: 0;
  transform: scale(0.95);
}

.slide-enter-to{ transform: scale(1); opacity: 1;}

.slide-enter-active{ transition: all 0.4s ease; }



header{
  position:relative;
  height:100vh;
  width:100%;
  z-index: 10;
  background-color:$myColor;
  color: $myWhite;
  font-family: 'Piazzolla', serif;

  

  .wrapper{
    max-width: 1445px;
  
      .logo-intro{
        margin-top: -80px;
        font-family: $myFont;
        color: $myWhite;
        font-size: clamp( 1.5rem, 9vw, 5.5rem);
           


      }
     .curseur{
          position: absolute;
          bottom: 35px;
          left: 50%;
          transform: translateX(-50%);
          opacity: 1;
        }
   
  }
  
}
main{
  
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  justify-content: center;

  .wrapper-main{
    max-width: 1445px;
 

    .top-main{
      max-height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $myColor;

      .sociaux{
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
              // margin-left: 13px;
              transform: scale(0.7);
            }
          
          }
      

    }
  }
  .description{
    h2, p{
      font-size: clamp( 1rem, 3vw, 2.8rem);
      font-family: $myFont;
    }
  }

  .services{
    .titre-services{
        margin-top: -20px;
        width:100%;
        padding-bottom: 10px;
        font-family: $myFont;
        border-bottom: 1px solid $myColor;
        font-size: clamp( 1rem, 2.1vw, 1.3rem);
      }
    .liste-services{
      margin-top: 20px;
      
        .bloc-services{
          min-height: 40px;
          font-size: clamp( 0.75rem, 1.75vw, 1.05rem);
          letter-spacing: .53px;
          color:  $myColor;
          padding: 10px 10px 10px 0;
        }
    }
   

  }
   .action{

    width: 100%;
    display: flex;
    justify-content: center;
      button{
       
        width: max(25%, 350px);
        height: 85px;
        background-color: $myColor;
        color: white;
        box-shadow: 1px 1px 1px gray;
        border-radius: 3px;
        font-family: $myFont;
        outline: none !important;
    
      }
    }


}

.form-bottom{
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 550px;
  margin-top: 100px;
  background-color: $myBlack;


    .footer-center{

      display: flex;
      justify-content: center;
    
    }
  

  .wraper-footer{
    max-width: 1445px;
    padding-bottom: 60px;
    min-height: 45vh;
    margin: auto;
    
  
    
    
  
    #section-savoir,
    #section-discuter,
    #section-debuter{
  
        color: $myGray;
        font-family: $myFont;
        font-size: clamp( 1rem, 2.1vw, 1.3rem);
        cursor: pointer;
        transition: all 0.4s;
      
    }

    #section-savoir:hover,
    #section-discuter:hover,
    #section-debuter:hover{
        color: $myLessGray;
        transition: all 0.3s;
    }
    
      #section-savoir:after,
      #section-discuter:after,
      #section-debuter:after{
        content: ""; 
        display: block; 
        width: min(95%, 280px); 
        padding-top: 20px;
        border-bottom: 1px solid var(--check-secondary, #787B7B);
        transition: all 1s;
    }
    
  
        label,.label-commentaire{
          color: $myWhite;
          font-family: $myFont;
          font-size: clamp( 0.75rem, 1.75vw, 1.05rem);
          letter-spacing: .53px;
         
        }
        .button-envoyer-wrapper{
          display: flex;
          align-items: center;

          button{
              border: 1px solid gray;
              color: $myBlack;
              font-family: $myFont;
              height: 110px;
              width: 110px;
              color: white;
              border-radius: 50%;
              transition: all 0.3s ease-out;
              outline: none !important;
           


            .centerIcon{
                            width:100%;
                            height: 100%;
                            margin: auto;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .lds-roller {
                                  display: inline-block;
                                  position: relative;
                                  width: 80px;
                                  height: 80px;
                                }
                                .lds-roller div {
                                  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                                  transform-origin: 40px 40px;
                                }
                                .lds-roller div:after {
                                  content: " ";
                                  display: block;
                                  position: absolute;
                                  width: 7px;
                                  height: 7px;
                                  border-radius: 50%;
                                  background: #fff;
                                  margin: -4px 0 0 -4px;
                                }
                                .lds-roller div:nth-child(1) {
                                  animation-delay: -0.036s;
                                }
                                .lds-roller div:nth-child(1):after {
                                  top: 63px;
                                  left: 63px;
                                }
                                .lds-roller div:nth-child(2) {
                                  animation-delay: -0.072s;
                                }
                                .lds-roller div:nth-child(2):after {
                                  top: 68px;
                                  left: 56px;
                                }
                                .lds-roller div:nth-child(3) {
                                  animation-delay: -0.108s;
                                }
                                .lds-roller div:nth-child(3):after {
                                  top: 71px;
                                  left: 48px;
                                }
                                .lds-roller div:nth-child(4) {
                                  animation-delay: -0.144s;
                                }
                                .lds-roller div:nth-child(4):after {
                                  top: 72px;
                                  left: 40px;
                                }
                                .lds-roller div:nth-child(5) {
                                  animation-delay: -0.18s;
                                }
                                .lds-roller div:nth-child(5):after {
                                  top: 71px;
                                  left: 32px;
                                }
                                .lds-roller div:nth-child(6) {
                                  animation-delay: -0.216s;
                                }
                                .lds-roller div:nth-child(6):after {
                                  top: 68px;
                                  left: 24px;
                                }
                                .lds-roller div:nth-child(7) {
                                  animation-delay: -0.252s;
                                }
                                .lds-roller div:nth-child(7):after {
                                  top: 63px;
                                  left: 17px;
                                }
                                .lds-roller div:nth-child(8) {
                                  animation-delay: -0.288s;
                                }
                                .lds-roller div:nth-child(8):after {
                                  top: 56px;
                                  left: 12px;
                                }
                                @keyframes lds-roller {
                                  0% {
                                    transform: rotate(0deg);
                                  }
                                  100% {
                                    transform: rotate(360deg);
                                  }
                                }



                                  }
                        .centerChecked{
                            height: 50px;
                            width: 50px;
                            margin: auto;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                        }


             
          }
          button:active {
              outline: none !important;
              border: none !important;
          }

        }
      }

   

   .blocProfil{
     position: relative;
     color: $myWhite;
     font: $myFont;
     font-size: clamp( 1.1rem, 1.5vw, 1.1rem);

     .desc{
       font-size: clamp( 0.75rem, 1.75vw, 1.05rem);
       letter-spacing: 0.53px;
     }
    

      .lien-courriel{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        a{
            
           text-decoration: underline;
        }
      }

   }

   .texte-projet{
     color: $myWhite;
     font: $myFont;
     font-size: clamp( 0.7rem, 1.5vw, 1.1rem);
   }
}

@media only screen and (max-width: 768px) {


.blocProfil{
  .desc{
     display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
  }


}



</style>
