<template>
  <router-view/>
</template>

<style>
*{
  padding: 0;
  margin: 0;
}
body{
  padding: 0;
  margin: 0;
}

</style>
